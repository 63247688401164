import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './assets/theme';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TemplateAdmin from 'pages/Admin/TemplateAdmin';
import { useSoftUIController } from 'context';
import themeDark from 'assets/theme-dark';
import TemplateEntry from 'pages/Entry/TemplateEntry';
import Login from 'pages/Login';
import TemplateVisitor from 'pages/TemplateVisitor';

function App() {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;

    return (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            <Routes>
                <Route exact path='/admin/*' element={<TemplateAdmin />} />
                <Route exact path='/entry/*' element={<TemplateEntry />} />
                <Route exact path='/*' element={<TemplateVisitor />} />
                {/* <Route exact path='/login' element={<Login />} /> */}
            </Routes>
        </ThemeProvider>
    );
}

export default App;
