import { Card, CardContent, FormControl, Grid, MenuItem, Select } from '@mui/material'
import SoftBox from 'components/SoftBox'
import SoftTypography from 'components/SoftTypography';
import { getDataWithResponse } from 'global/getFunc';
import React, { useEffect, useState } from 'react'
import BarDrilldownCaleg from "myComponent/Charts/BarDrilldownCaleg";
import BarDrilldownDPR from "myComponent/Charts/BarDrilldownDPR";

const Dpr = () => {
    const [tableData, setTableData] = useState({});

    const [parpolData, setParpolData] = useState([])
    const [selectedParpol, setSelectedParpol] = useState(0)

    const requestData = () => {
        getDataWithResponse(
            '/perolehan/' + 2 + '/' + 0,
            null,
            null,
            setTableData,
            null
        );
    }

    const requestPerolehanCaleg = () => {
        getDataWithResponse(
            `/perolehan-caleg/${selectedParpol}/2/1`,
            null,
            null,
            setParpolData,
            null
        )
    }

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        console.log(selectedParpol);
        selectedParpol !== 0 && requestPerolehanCaleg()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedParpol])

    return (
        <>
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ p: 1 }}>
                        <BarDrilldownDPR
                            kecamatans={tableData.data || []}
                            title='Perolehan Suara DPR RI daerah Pemilihan SULTRA'
                            total={parseInt(tableData.totalSuara)}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <SoftBox mb={2}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Pilih Parpol
                                            </SoftTypography>
                                        </SoftBox>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={selectedParpol}
                                                label="Role"
                                                onChange={(e) => setSelectedParpol(e.target.value)}
                                            >
                                                {
                                                    tableData.data?.length > 0 && tableData.data.map((parpol, index) => (
                                                        <MenuItem key={index} value={parpol.id}>{parpol.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                                {
                                    selectedParpol !== 0 &&
                                    <Grid item xs={12}>
                                        <BarDrilldownCaleg
                                            kecamatans={parpolData}
                                            title='Perolehan Suara DPR RI daerah Pemilihan SULTRA'
                                            total={parseInt(tableData.totalSuara)}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Dpr