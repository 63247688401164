import { Groups, Groups2, Groups3, Home, PersonSearch } from "@mui/icons-material";
import { decodeToken } from "react-jwt";

const entryRoutes = (params) => {
    const usr = decodeToken(params);
    return ([
        {
            type: "route",
            name: "Dashboard",
            key: [''],
            route: "/entry",
            icon: <Home size="12px" color="warning" />,
            noCollapse: true,
            level: 'all'
        },
        {
            type: "route",
            name: "PILPRES",
            key: ['pilpres'],
            route: "/entry/pilpres",
            icon: <PersonSearch size="12px" color="info" />,
            noCollapse: true,
            level: 'ri',
            tps: usr.tps,
            pemilihan: 1
        },
        {
            type: "route",
            name: "DPR RI",
            key: ['dpr-ri'],
            route: "/entry/dpr-ri",
            icon: <Groups size="12px" color="success" />,
            noCollapse: true,
            level: 'ri',
            tps: usr.tps,
            pemilihan: 2
        },
        {
            type: "route",
            name: "DPD",
            key: ['dpd'],
            route: "/entry/dpd",
            icon: <Groups3 size="12px" color="error" />,
            noCollapse: true,
            level: 'ri',
            tps: usr.tps,
            pemilihan: 3
        },
        {
            type: "route",
            name: "DPRD Prov",
            key: ['dprd-prov'],
            route: "/entry/dprd-prov",
            icon: <Groups2 size="12px" color="primary" />,
            noCollapse: true,
            level: 'prov',
            tps: usr.tps,
            pemilihan: 4
        },
    ])
}

export default entryRoutes;