import { FileDownload, Info } from "@mui/icons-material";
import { Box, Button, Card, CardContent, CardHeader, Chip, Grid, IconButton, Typography } from "@mui/material";
import MiniStatisticsCard from "components/Cards/StatisticsCards/MiniStatisticsCard";
import SoftBox from "components/SoftBox";
import { onValue, ref } from "firebase/database";
import { firebaseDB } from "global/Firebase";
import { CustomDescrypt } from "global/func";
import { getDataWithResponse } from "global/getFunc";
import MaterialReactTable from "material-react-table";
import BarDrilldownDPD from "myComponent/Charts/BarDrilldownDPD";
import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import userStore from "store/userStore";
import Detail from "./Detail";
import { ExportToCsv } from "export-to-csv";

const DataDPD = () => {
    return (
        <Routes>
            <Route exact path="" element={<Data />} />
            <Route exact path="detail" element={<Detail />} />
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState({});
    const [loading, setLoading] = useState(false)
    const [listPsu, setListPsu] = useState([])

    useEffect(() => {
        console.log(tableData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData])

    const db = firebaseDB
    const pilpres = ref(db, 'dpd');

    useEffect(() => {
        onValue(pilpres, () => {
            console.log('update');
            requestData()
            requestPSU()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestData = () => {
        getDataWithResponse(
            '/admin/perolehan/' + 3 + '/' + 0,
            decryptedUser,
            null,
            setTableData,
            logOut
        );
    }

    const requestPSU = () => {
        getDataWithResponse(
            '/admin/psu-dpd',
            decryptedUser,
            setLoading,
            setListPsu,
            logOut
        )
    }

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
            filterVariant: 'text',
            size: 50,
            Cell: ({ cell }) => {
                switch (cell.row.original.update_dpd) {
                    case null:
                        return cell.row.original.id

                    default:
                        return <Chip label={cell.row.original.id} color="success" />
                }
            }
        },
        {
            accessorKey: 'nm_kab',
            header: 'Kabupaten',
        },
        {
            accessorKey: 'nm_kec',
            header: 'Kecamatan'
        },
        {
            accessorKey: 'nm_kel',
            header: 'Desa/Kelurahan'
        },
        {
            accessorKey: 'tps',
            header: 'TPS'
        },
        {
            accessorKey: 'entry',
            header: 'Nama'
        },
        {
            accessorKey: 'update_dpd',
            header: 'Waktu Input',
        },
    ]

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={6}>
                    <MiniStatisticsCard
                        title={{ text: "Data Masuk (PSU)" }}
                        count={tableData.jumlahPsu ? ((tableData.jumlahPsu.length / listPsu.length) * 100).toFixed(2).toLocaleString() + '%' : 0}
                        percentage={{ color: "success", text: tableData.jumlahPsu ? tableData.jumlahPsu.length : 0 }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <MiniStatisticsCard
                        title={{ text: "Total PSU" }}
                        count={listPsu.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} mb={3}>
                <Grid item xs={12} lg={12}>
                    <Card sx={{ p: 1 }}>
                        <BarDrilldownDPD
                            kecamatans={tableData.data}
                            title='Perolehan Suara DPD Sulawesi Tenggara'
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    List PSU
                                </Typography>
                            }
                        />
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={listPsu}
                                        enableRowActions
                                        // enablePagination={false}
                                        renderRowActions={({ row, table }) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'nowrap', gap: '8px' }}>
                                                <IconButton
                                                    color="info"
                                                    component={Link}
                                                    to='detail'
                                                    state={row.original}
                                                >
                                                    <Info />
                                                </IconButton>
                                            </Box>
                                        )}

                                        muiTablePaginationProps={{
                                            rowsPerPageOptions: [10, 20, 50, { label: 'All', value: listPsu.length }],
                                            showFirstButton: true,
                                            showLastButton: true,
                                        }}

                                        state={{
                                            isLoading: loading,
                                            showProgressBars: loading
                                        }}
                                        muiCircularProgressProps={{
                                            color: 'secondary',
                                            thickness: 5,
                                            size: 55,
                                        }}
                                        initialState={{
                                            showColumnFilters: true
                                            // pagination: { pageSize: 10, pageIndex:1 }
                                        }}
                                        renderTopToolbarCustomActions={({ table }) => (
                                            <Box
                                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                            >
                                                <Button
                                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                    //export all rows, including from the next page, (still respects filtering and sorting)
                                                    onClick={() =>
                                                        handleExportRows(table.getPrePaginationRowModel().rows)
                                                    }
                                                    startIcon={<FileDownload />}
                                                    variant="contained"
                                                >
                                                    Export Rows
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default DataDPD