/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function DefaultNavbarLink({ icon, name, route, light }) {
    return (
        <SoftBox
            component={Link}
            to={route}
            mx={1}
            p={1}
            display="flex"
            alignItems="center"
            sx={{ cursor: "pointer", userSelect: "none" }}
        >
            <Icon
                sx={{
                    color: ({ palette: { white, secondary } }) => (light ? white.main : secondary.main),
                    verticalAlign: "middle",
                }}
            >
                {icon}
            </Icon>
            <SoftTypography
                variant="button"
                fontWeight="regular"
                color={light ? "white" : "dark"}
                textTransform="capitalize"
                sx={{ width: "100%", lineHeight: 0 }}
            >
                &nbsp;{name}
            </SoftTypography>
        </SoftBox>
    );
}

// Typechecking props for the DefaultNavbarLink
DefaultNavbarLink.propTypes = {
    icon: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    light: PropTypes.bool.isRequired,
};

export default DefaultNavbarLink;
