import { Card, Grid } from '@mui/material'
import { getDataWithResponse } from 'global/getFunc'
import React, { useEffect, useState } from 'react'
import BarDrilldownDPD from "myComponent/Charts/BarDrilldownDPD";

const Dpd = () => {

    const [tableData, setTableData] = useState({});

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const requestData = () => {
        getDataWithResponse(
            '/perolehan/' + 3 + '/' + 0,
            null,
            null,
            setTableData,
            null
        );
    }

    return (
        <Grid container spacing={3} mb={3}>
            <Grid item xs={12} lg={12}>
                <Card sx={{ p: 1 }}>
                    <BarDrilldownDPD
                        kecamatans={tableData.data}
                        title='Perolehan Suara DPD Sulawesi Tenggara'
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default Dpd