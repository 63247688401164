import { Card, CardContent, CardMedia, Container, FormControl, Grid, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

import logo from "assets/images/dsi_logo.png"
// import tyas from 'assets/images/tyas.png'
import bg from "assets/images/bg.png"
import { decodeToken } from 'react-jwt';
import userStore from 'store/userStore';
import { CustomDescrypt } from 'global/func';
import { serverUrl } from 'global/variable';
import { CustomEncrypt } from 'global/func';
import { LoadingButton } from '@mui/lab';
import BarDrilldown from 'myComponent/Charts/BarDrilldown';
import BarDrilldownDPR from 'myComponent/Charts/BarDrilldownDPR';
import { getDataWithResponse } from 'global/getFunc';
import Pilpres from './Pilpres';
import Dpr from './Dpr';
import Dpd from './Dpd';
import Dprd from './Dprd';

const Home = () => {
    const pathname = useLocation();
    const navigate = useNavigate();
    const { user, setUser } = userStore((state) => ({ user: state.user, setUser: state.setUser }));
    const userData = localStorage.getItem('AuthPersist');

    const [dprData, setDprData] = useState([])
    const requestDataDpr = () => {
        getDataWithResponse(
            '/perolehan/' + 2 + '/' + 0,
            null,
            null,
            setDprData,
            null
        );
    }
    useEffect(() => {
        requestDataDpr()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <SoftBox position="relative" p={2} >
                <SoftBox
                    display="grid"
                    alignItems="center"
                    position="relative"
                    minHeight="25rem"
                    paddingTop='0px !important'
                    // marginTop='-10rem'
                    // borderRadius="xl"
                    sx={{
                        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                            `${linearGradient(
                                rgba(gradients.info.main, 0),
                                rgba(gradients.info.state, 0)
                            )}, url(${bg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        overflow: "hidden",
                    }}
                >

                    <Grid container justifyContent='center' mt={-5} width='100%'>
                        <Grid item>
                            {/* <img src={{lg: slogan_white, xs: logo}} alt='logo' width='100%' height='250px' style={{ transform: [{ scaleX: -1 }] }} /> */}
                            <CardMedia
                                src={logo}
                                component="img"
                                title='fgkejfhgeujgu'
                                sx={{
                                    // maxWidth: "100%",
                                    height: { lg: '300px', xs: '280px' },
                                    // margin: 0,
                                    // marginTop: '10px',
                                    // paddingTop: '10px',
                                    // boxShadow: ({ boxShadows: { md } }) => md,
                                    // objectFit: "cover",
                                    // objectPosition: "center",
                                    // zIndex: 9999
                                }}
                            />
                        </Grid>
                    </Grid>
                </SoftBox>
                <Container>
                    <SoftBox pt={5}>
                        <Pilpres />
                        <Dpr />
                        <Dpd />
                        <Dprd />
                    </SoftBox>
                </Container>
            </SoftBox>
        </>

    )
}

export default Home