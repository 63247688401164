import { HighchartsReact } from 'highcharts-react-official'
import Highcharts from 'highcharts'
import drilldown from "highcharts/modules/drilldown.js";
import { useEffect, useState } from 'react';
import { useSoftUIController } from 'context';

require("highcharts/modules/exporting")(Highcharts);
drilldown(Highcharts)

const BarDrilldownCaleg = ({ kecamatans = [], title }) => {
    const [controller] = useSoftUIController();
    const { darkMode } = controller;

    // const [data, setData] = useState([]);
    const [options, setOptions] = useState(null)

    // useEffect(() => {
    //     let dtx = []
    //     console.log(kecamatans);
    //     kecamatans.forEach((kecamatan, index) => {
    //         let dt = {
    //             // id: kecamatan.id_kec,
    //             y: kecamatan.y,
    //             nilai: kecamatan.nilai,
    //             name: kecamatan.name,
    //             color: kecamatan.color,
    //             no_urut: kecamatan.no_urut,
    //             id: kecamatan.id
    //         }
    //         dtx[index] = dt;
    //     });
    //     setData(dtx)
    //     console.log(dtx);
    //     console.log(total);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [kecamatans])

    useEffect(() => {
        options !== null &&
        setOptions(optionsx => {
            const copy = {...optionsx}
            copy.series[0].data = kecamatans
            return copy
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans])

    useEffect(() => {
        setOptions({
            chart: {
                type: 'bar',
                height: 700,
                backgroundColor: darkMode ? '#111c44' : '#ffffff',
            },
            title: {
                align: 'center',
                text: title,
                style: {
                    color: darkMode ? '#ffffff' : '#1E1E1E'
                }
                // margin: 35
            },
            subtitle: {
                align: 'center',
                // text: 'Klik kecamatan untuk melihat Data Kelurahan',
                style: {
                    color: darkMode ? '#ffffff' : '#1E1E1E'
                }
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category',
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#1E1E1E',
                        fontWeight: 'bold'
                    },
                    // useHTML: true,
                    // formatter: function () {
                    //     const datax = getCurrentData(this.pos, kecamatans)
                    //     return `<img src="${serverUrl}/capres/${datax.id}.png" style="width: 100px; height: 150px;" />`;
                    // },
                }
            },
            yAxis: {
                title: {
                    text: 'Perolehan',
                    style: {
                        color: darkMode ? '#ffffff' : '#1E1E1E'
                    }
                },
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: darkMode ? '#ffffff' : '#1E1E1E'
                    }
                }
            },
            legend: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: true,
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        formatter: function() {
                            // return Highcharts.numberFormat(this.point.nilai, 0) + '(' + Highcharts.numberFormat(this.y, 2) + '%)';
                            return Highcharts.numberFormat(this.y, 2) + '%';
                        }
                    }
                }
            },

            tooltip: {
                enabled: true,
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b>'
            },
            series: [
                {
                    name: 'PEROLEHAN',
                    colorByPoint: true,
                    data: kecamatans
                },
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'left'
                    }
                },
                activeAxisLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e',
                    fontWeight: 'normal'
                },
                activeDataLabelStyle: {
                    textDecoration: 'none',
                    color: darkMode ? '#ffffff' : '#67748e'
                },
                series: []
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kecamatans, darkMode])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default BarDrilldownCaleg