import { AddCircle, ArrowBack, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, InputAdornment, ListItemIcon, MenuItem, Modal, Tooltip } from '@mui/material'
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore';

const EditEntry = ({ data, text, deleting, setDeleting, ...props }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        nama: '',
        no_hp: '',
        tps: data.id
    })

    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        open && console.log(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    // console.log(data);

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/admin/entry/insert',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        )
    }

    const afterSubmit = () => {
        setDeleting(!deleting)
        setFormData({})
        handleClose()
        // setSrc()
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            {/* <Button onClick={handleOpen} {...props}>{text}</Button> */}
            <MenuItem
                onClick={handleOpen}
                sx={{ m: 0 }}
                {...props}
            >
                <ListItemIcon>
                    <AddCircle color='primary' />
                </ListItemIcon>
                Tambah Entry
            </MenuItem>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <>
                    <Grid container spacing={3} justifyContent='center' py={5}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Card >
                                <CardHeader
                                    title={
                                        <SoftTypography variant='h6'>Kembali</SoftTypography>
                                    }
                                    avatar={
                                        <Tooltip title="Kembali">
                                            <IconButton variant="outlined" onClick={handleClose}><ArrowBack /></IconButton>
                                        </Tooltip>
                                    }
                                />
                                {
                                    loading ?
                                        (
                                            <Grid container spacing={3} py={5} justifyContent="center">
                                                <Grid item>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <CircularProgress />
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        (
                                            <CardContent component='form' onSubmit={submit}>
                                                <SoftBox>
                                                    <SoftTypography variant='h6'>Kabupaten {data.nm_kab}</SoftTypography>
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftTypography variant='h6'>Kecamatan {data.nm_kec}</SoftTypography>
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftTypography variant='h6'>Desa/Kelurahan {data.nm_kel}</SoftTypography>
                                                </SoftBox>
                                                <SoftBox mb={2}>
                                                    <SoftTypography variant='h6'>TPS {data.tps}</SoftTypography>
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Nama
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.nama}
                                                        onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                        autoComplete='off'
                                                    />
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Username
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.username}
                                                        onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                        autoComplete='off'
                                                    />
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Password
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type={showPassword ? 'text' : 'password'}
                                                        placeholder="Password"
                                                        defaultValue={formData.password}
                                                        onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                                                        autoComplete='off'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    onMouseDown={handleMouseDownPassword}
                                                                >
                                                                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </SoftBox>
                                                <SoftBox>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            No. HP
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <SoftInput
                                                        type="text"
                                                        defaultValue={formData.no_hp}
                                                        onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                        autoComplete='off'
                                                    />
                                                </SoftBox>
                                                <SoftBox mb={1} ml={0.5} mt={3} display="flex" flexDirection="row" justifyContent="end">
                                                    <LoadingButton
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        startIcon={<Save />}
                                                        variant="contained"
                                                        type='submit'
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </SoftBox>
                                            </CardContent>
                                        )
                                }

                            </Card>
                        </Grid>
                    </Grid >
                </>
            </Modal >
        </>
    )
}

export default EditEntry