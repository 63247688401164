import { Card, CardMedia, Grid } from '@mui/material'
import SoftAvatar from 'components/SoftAvatar'
import SoftBox from 'components/SoftBox'
import React from 'react'

import logo from "assets/images/thi_512.png"
import logo_tulisan from "assets/images/dsi_logo.png"
import bg from "assets/images/bg.png"
import bos from "assets/images/bos.png"
import { CustomDescrypt } from 'global/func'
import { decodeToken } from 'react-jwt'
import SoftTypography from 'components/SoftTypography'

const Headerx = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const user = decodeToken(decryptedUser)

    return (
        <SoftBox position="relative" sx={{ paddingTop: '0px !important' }}>
            <SoftBox
                display="grid"
                alignItems="center"
                position="relative"
                minHeight="22rem"
                paddingTop='0px !important'
                // marginTop='-10rem'
                // borderRadius="xl"
                sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                            rgba(gradients.info.main, 0),
                            rgba(gradients.info.state, 0)
                        )}, url(${bg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                }}
            >
                <Grid container justifyContent={{ lg: 'flex-start', xs: 'center' }} mt={{ lg: -1.5, xs: -7 }} >
                    <Grid item ml={{ lg: 5 }}  sx={{zIndex: 999}}>
                        {/* <SoftBox component={Image} src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        {/* <img src={giona} alt='giona' width='100%' height='215px' style={{transform: [{scaleX: -1}]}} /> */}
                        <CardMedia
                            src={bos}
                            component="img"
                            title={user.status}
                            sx={{
                                maxWidth: "100%",
                                height: { lg: '280px', xs: '280px' },
                                margin: 0,
                                // boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                                zIndex: 999
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' mt={-40} width='100%' display={{ xs: 'none', lg: 'flex' }}>
                    <Grid item>
                        <img src={logo_tulisan} alt='logo' width='100%' height='150px' style={{ transform: [{ scaleX: -1 }] }} />
                    </Grid>
                </Grid>
            </SoftBox>
            <Card
                sx={{
                    backdropFilter: `saturate(200%) blur(30px)`,
                    backgroundColor: ({ functions: { rgba }, palette: { background } }) => rgba(background.default, 0.8),
                    // backgroundColor: 'white',
                    // opacity: 0.8,
                    boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
                    position: "relative",
                    mt: -8,
                    mx: 3,
                    py: 2,
                    px: 2,
                }}
            >
                <SoftBox>
                    <Grid container spacing={2} justifyContent='flex-start'>
                        <Grid item xs='auto' >
                            <SoftAvatar
                                src={logo}
                                alt="profile-image"
                                variant="rounded"
                                size="xl"
                                shadow="sm"
                            />
                        </Grid>
                        <Grid item>
                            <SoftBox height="100%" my={1} lineHeight={1} width="100%">
                                <SoftTypography variant="h5" width="100%">
                                    {/* {decryptedUser.role === '2' ? 'Admin' : 'Tim'} */}
                                    {user.nama}
                                </SoftTypography>
                                <SoftTypography variant="body2" width="100%">
                                    {user.status}
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                    </Grid>
                </SoftBox>
            </Card>
        </SoftBox>
    )
}

export default Headerx