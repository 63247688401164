import { Card, Grid } from '@mui/material'
import { getDataWithResponse } from 'global/getFunc'
import BarDrilldown from 'myComponent/Charts/BarDrilldown'
import React, { useEffect, useState } from 'react'

const Pilpres = () => {
    const [pilpresData, setPilpresData] = useState([])
    const requestDataPilpres = () => {
        getDataWithResponse(
            '/perolehan/' + 1 + '/' + 0,
            null,
            null,
            setPilpresData,
            null
        );
    }
    useEffect(() => {
        requestDataPilpres()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Grid container spacing={3} mb={3}>
            <Grid item xs={12} lg={12}>
                <Card sx={{ p: 1 }}>
                    <BarDrilldown
                        kecamatans={pilpresData.data || []}
                        total={parseInt(pilpresData.totalSuara)}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default Pilpres