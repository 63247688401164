import { BarChart, Home, Interests, Person, Place } from "@mui/icons-material";

const adminRoutes = [
    {
        type: "route",
        name: "Dashboard",
        key: [''],
        route: "/admin",
        icon: <Home size="12px" color="warning" />,
        noCollapse: true,
        level: 'all'
    },
    {
        type: "collapse",
        name: "Data",
        key: ['data-pilpres', 'data-dpr', 'data-dpd', 'data-dprd'],
        icon: <Interests size="12px" color="secondary" />,
        collapse: [
            {
                name: "Pilpres",
                key: ["/admin/data-pilpres", "/admin/data-pilpres/detail"],
                route: "/admin/data-pilpres"
            },
            {
                name: "DPR RI",
                key: ["/admin/data-dpr", "/admin/data-dpr/detail"],
                route: "/admin/data-dpr"
            },
            {
                name: "DPD",
                key: ["/admin/data-dpd", "/admin/data-dpd/detail"],
                route: "/admin/data-dpd"
            },
            {
                name: "DPRD Prov",
                key: ["/admin/data-dprd", "/admin/data-dprd/detail"],
                route: "/admin/data-dprd"
            },
        ],
    },
    {
        type: "route",
        name: "Real Count",
        key: ['real-count', 'real-count/detail'],
        route: "/admin/real-count",
        icon: <BarChart size="12px" color="error" />,
        noCollapse: true,
        level: 'all'
    },
    {
        type: "route",
        name: "PSU",
        key: ['psu'],
        route: "/admin/psu",
        icon: <Place size="12px" color="info" />,
        noCollapse: true,
        level: 'all'
    },
    { type: "divider", title: "Account Pages", key: "settings-divider" },
    { type: "title", title: "Settings", key: "settings" },
    {
        type: "collapse",
        name: "Manajemen User",
        key: ['vg', 'korkel', 'thi_tim'],
        icon: <Person size="12px" color="warning" />,
        collapse: [
            {
                name: "Entry Data",
                key: ["/admin/entry", "/admin/entry/tambah", "/admin/entry/detail"],
                route: "/admin/entry"
            },
            {
                name: "Admin",
                key: ["/admin/admin", "/admin/admin/tambah", "/admin/admin/detail"],
                route: "/admin/admin"
            },
        ],
    }
];

export default adminRoutes;
