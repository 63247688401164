import { Grid } from '@mui/material'
import DefaultNavbar from 'components/DefaultNavbar'
import Footer from 'components/Footer'
import PageLayout from 'components/PageLayout'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Login from './Login'

const TemplateVisitor = () => {
    return (
        <PageLayout background="white">
            <DefaultNavbar
                action={{
                    type: "external",
                    route: "/",
                    label: "free download",
                    color: "dark",
                }}
            />
            <Routes>
                <Route exact path="" element={<Home />} />
                <Route exact path='/login' element={<Login />} />
            </Routes>
            <Footer />
        </PageLayout>
    )
}

export default TemplateVisitor