import { Box, Button, Card, CardContent, CardHeader, Grid, ListItemIcon, MenuItem, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { getDataWithResponse } from 'global/getFunc'
import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import userStore from 'store/userStore'
import { Delete, FileDownload } from '@mui/icons-material'
import MaterialReactTable from 'material-react-table'
import Swal from 'sweetalert2'
import { postDelete } from 'global/postFunc'
import EditEntry from './EditEntry'
import { ExportToCsv } from 'export-to-csv'

const PSU = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            {/* <Route exact path='detail' element={<Detail />} /> */}
            {/* <Route exact path='tambah' element={<Tambah />} /> */}
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)
    const [loading, setLoading] = useState(true)

    const requestData = () => {
        getDataWithResponse(
            '/admin/psu',
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const columns = [
        //fields = { ['no_kk', 'nik_kepala', 'nama_kepala', 'pekerjaan', 'jml_anggota', 'no_hp', 'created_at', 'nama_petugas']}
        //headers = { ['No KK', 'NIK KK', 'Nama KK', 'Pekerjaan', 'Jml Anggota', 'No. HP', 'Jam Input', 'Tim']}
        {
            accessorKey: 'id',
            header: 'ID',
            size: 50,
            filterVariant: 'text',
        },
        {
            accessorKey: 'nm_kab',
            header: 'Kabupaten',
        },
        {
            accessorKey: 'nm_kec',
            header: 'Kecamatan'
        },
        {
            accessorKey: 'nm_kel',
            header: 'Desa/Kelurahan'
        },
        {
            accessorKey: 'tps',
            header: 'TPS'
        },
        {
            accessorKey: 'entry',
            header: 'Nama'
        },
        {
            accessorKey: 'entryUsername',
            header: 'Username'
        },
        {
            accessorKey: 'entryPassword',
            header: 'Password'
        },
    ]

    const hapusData = (params) => {
        Swal.fire({
            title: 'Hapus User?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                postDelete(
                    '/admin/entry/delete',
                    {
                        _method: 'DELETE',
                        username: params
                    },
                    decryptedUser,
                    null,
                    () => { setDeleting(!deleting) },
                    logOut
                )
            }
        })
    }

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    return (
        <SoftBox my={3}>
            <Grid container spacing={3} mb={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <MiniStatisticsCard
                        title={{ text: "Total Data" }}
                        count={tableData.length}
                        // percentage={{ color: "success", text: "+55%" }}
                        icon={{ color: "info", component: "paid" }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={12} lg={12}>
                    <Card>
                        <CardHeader
                            title={
                                <Typography>
                                    Tabel Entry
                                </Typography>
                            }
                        // action={
                        //     <Tambah
                        //         text="Tambah"
                        //         endIcon={<AddCircleOutline color='white' />}
                        //         variant="contained"
                        //         deleting={deleting}
                        //         setDeleting={setDeleting}
                        //     />
                        // }
                        />
                        <CardContent>
                            <Grid container justifyContent="space-between" >
                                <Grid item xs={12}>
                                    <MaterialReactTable
                                        columns={columns}
                                        data={tableData}
                                        enableRowActions
                                        // enablePagination={false}
                                        enableColumnFilters
                                        renderRowActionMenuItems={({ row }) => [
                                            <EditEntry
                                                key={0}
                                                // text="Tambah Entry"
                                                // endIcon={<AddCircleOutline color='white' />}
                                                // variant="contained"
                                                deleting={deleting}
                                                setDeleting={setDeleting}
                                                data={row.original}
                                            />,
                                            <MenuItem
                                                key={2}
                                                onClick={() => {
                                                    // View profile logic...
                                                    hapusData(row.original.username);
                                                }}
                                                sx={{ m: 0 }}
                                            >
                                                <ListItemIcon>
                                                    <Delete color='error' />
                                                </ListItemIcon>
                                                Hapus
                                            </MenuItem>
                                        ]}

                                        muiTablePaginationProps={{
                                            rowsPerPageOptions: [10, 20, 50, { label: 'All', value: tableData.length }],
                                            showFirstButton: true,
                                            showLastButton: true,
                                        }}

                                        state={{
                                            isLoading: loading,
                                            showProgressBars: loading
                                        }}
                                        muiCircularProgressProps={{
                                            color: 'secondary',
                                            thickness: 5,
                                            size: 55,
                                        }}
                                        initialState={{
                                            showColumnFilters: true
                                            // pagination: { pageSize: 10, pageIndex:1 }
                                        }}
                                        renderTopToolbarCustomActions={({ table }) => (
                                            <Box
                                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                                            >
                                                <Button
                                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                    //export all rows, including from the next page, (still respects filtering and sorting)
                                                    onClick={() =>
                                                        handleExportRows(table.getPrePaginationRowModel().rows)
                                                    }
                                                    startIcon={<FileDownload />}
                                                    variant="contained"
                                                >
                                                    Export Rows
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </SoftBox>
    )
}

export default PSU